import { Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { ActivatedRoute } from '@angular/router';
import { InterventionModel } from "../dataModels/intervention-model"
import { Location } from "@angular/common";

@Component({
  selector: 'app-use-conditions',
  templateUrl: './use-conditions.component.html',
  styleUrls: ['./use-conditions.component.scss']
})
export class UseConditionsComponent implements OnInit {

  private curIntervention: InterventionModel;
  loading: boolean = true;
  customConditions: string;
  token: string;
  device: any;
  userLang: any;
  templateString: string;
  cookiesPolicy: Boolean = false;
  showCookiesPolicy: Boolean = false;

  constructor(
    public remoteService: RemoteLibraryService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.remoteService = remoteService;

  }

  async ngOnInit() {
    this.token = this.route.snapshot.params.token;
    const type: 'all' | 'use' | 'terms' | 'cookies' = this.route.snapshot.params.type;
    this.device = this.remoteService.interventionService.browserCheck();
    this.remoteService.interventionService.changeLabels();
    this.remoteService.languageService.browserLanguage();
    this.userLang = window.navigator.language.substr(0,2) || 'en';
    this.remoteService.interventionService.getIntervention(this.token, this.device.userAgent).subscribe((intervention: any) => {
      this.curIntervention = intervention;
      if (this.curIntervention.company_config) {
        const { useCondition, usePrivacy, cookiesPolicy, fixedLanguage } = this.curIntervention.company_config;

        if (fixedLanguage) {
          this.userLang = fixedLanguage.substr(0,2);
        }

        if (['all', 'terms'].includes(type) && useCondition) {
          this.customConditions = useCondition;
        }

        if (type === 'use' && usePrivacy) {
          this.customConditions = usePrivacy;
        }

        if (type === 'cookies' && cookiesPolicy) {
          this.customConditions = cookiesPolicy.content;
        }
        if (this.location.path().includes('cookies')) {
          this.cookiesPolicy = this.curIntervention.company_config.cookiesPolicy.show || false;
        }
      }
      this.templateString = require(`./contents/${type}/${this.tcLangValidated()}.html`);
      this.loadDynamicStyles(this.curIntervention.customStylesFolder);
      this.loading = false;
    });
  }

  tcLangValidated() {
    if (this.userLang !== 'es' && this.userLang !== 'de') {
      return 'en';
    }
    return this.userLang;
  }

  backClicked() {
    this.location.back();
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        /* eslint-disable global-require */
        /* eslint-disable import/no-dynamic-require */
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
        /* eslint-enable import/no-dynamic-require */
        /* eslint-enable global-require */
        sessionStorage.setItem('customStylesFolder', customStylesFolder);
      } catch (error) {
        /* eslint-disable no-console */
        console.log(`No se han podido cargar los estilos personalizados de la compañía. ERROR: ${error}`);
        /* eslint-enable no-console */
      }
    }
  }

}
