import { Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  customStylesFolder: string = sessionStorage.getItem('customStylesFolder') || undefined;

  constructor(
    public remoteService: RemoteLibraryService,
  ) { }

  ngOnInit() {
    this.loadDynamicStyles();
  }
  loadDynamicStyles(){
    if(this.customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${this.customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log("err",error)
      }
    }
  }
}
