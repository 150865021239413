import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routes } from './routes';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FinalPageComponent } from './final-page/final-page.component';
import { CallPageComponent } from './call-page/call-page.component';
import { PermissionErrorComponent } from './permission-error/permission-error.component';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { RemoteLibraryService, RemoteLibraryModule } from 'remote-library';
import { FormsModule } from '@angular/forms';
import { UseConditionsComponent } from './use-conditions/use-conditions.component';
import { SafePipe } from './safe.pipe';
import { environment } from '../../../remote-library/src/environments/environment';
import { WarningBrowserComponent } from './warning-browser/warning-browser.component';


@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    NavbarComponent,
    FinalPageComponent,
    CallPageComponent,
    UseConditionsComponent,
    PermissionErrorComponent,
    SafePipe,
    WarningBrowserComponent,
  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    RouterModule.forRoot(routes, {useHash: true}),
    RemoteLibraryModule,
  ],
  providers: [
    RemoteLibraryService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    console.log('A change...');
  }
}
