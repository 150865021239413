import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { RemoteLibraryService } from 'remote-library';
import { InterventionModel } from "../dataModels/intervention-model"
import { take } from 'rxjs/operators';

export enum IotMessageType {
  CREATE_SESSION = 0,
  CALL_DROPPED = 1,
  DECLINED_CALL = 2,
  ANSWERED_CALL = 3,
  INTERVENTION_STATUS_CHANGE = 4,
  CALL_HOLDED = 7,
  COMPANY_INVITE_CATEGOR = 8,
  INTERVENTION_CREATED = 9,
  IMAGE_ADDED = 10,
  JOIN_SESSION = 11
}

@Component({
  selector: 'app-call-page',
  templateUrl: './call-page.component.html',
  styleUrls: ['./call-page.component.scss']
})
export class CallPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('requestCameraPermissionsButton') public permitCameraButton: ElementRef;
  actualIntervention: InterventionModel;
  token: string;
  device: any;
  incomCall: boolean = false;
  vertical: Boolean = false;
  scheduledDate: string = sessionStorage.getItem('scheduledDate');
  scheduledTime: string = sessionStorage.getItem('scheduledTime');
  additionalData: boolean = true;
  hideScheduledDate = sessionStorage.getItem('hideScheduledDate') == 'false' ? false : true;
  hideSpinner = sessionStorage.getItem('hideSpinner') == 'false' ? false : true;
  hideMainInfo = sessionStorage.getItem('hideMainInfo') == 'false' ? false : true;
  hideAdditionalInfo = sessionStorage.getItem('hideAdditionalInfo') == 'false' ? false : true;
  disableSlaveCallBtn = sessionStorage.getItem('disableSlaveCallBtn') == 'false' ? false : true;
  loading: boolean = true;
  showPermitsRequest: boolean;
  loadingPermits: boolean;
  conditionsAcceptedFlag: boolean;
  permissionAccepted: boolean;
  ongoingCall: boolean;
  showLegalFooter: boolean = false;
  public audio = true;

  constructor(
    public http: Http,
    private router: Router,
    public remoteService: RemoteLibraryService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.remoteService.callService.homeChangeDetector = changeDetector;
  }

  ngOnInit() {
    this.ongoingCall = false;
    this.showPermitsRequest = true;
    this.device = this.remoteService.interventionService.browserCheck();
    this.token = this.remoteService.interventionService.userToken;
    this.vertical = window.matchMedia("(orientation: portrait)").matches
    window.addEventListener('orientationchange', () => {
      this.vertical = !window.matchMedia("(orientation: portrait)").matches
    }, false);
    
    this.getInformation();
  }

  ngAfterViewInit() {
    this.remoteService.callService.ringtoneService.addListener(this.permitCameraButton.nativeElement);
  }

  ngOnDestroy() {
    this.remoteService.callService.ringtoneService.removeListener(this.permitCameraButton.nativeElement);
  }

  closeModal() {
    this.loadingPermits = true;

    const { smartvideo: { disableAudio } = { disableAudio: false } } = this.remoteService.interventionService.actualIntervention.company_config;
    this.audio = !disableAudio;
    this.remoteService.callService.audio = this.audio;
    this.remoteService.callService.enumerateMediaDevices(this.audio, (err) => {
      if (!this.actualIntervention) {
        this.actualIntervention = this.remoteService.interventionService.actualIntervention;
      }
      if (err) {
        console.log(err);
        this.permissionAccepted = false;
        this.remoteService.interventionService.update(this.token, this.actualIntervention.id, {
          'failedPermissions': true
        }).pipe(take(1)).subscribe();
        this.router.navigate(['no-permission-error',this.token]);
      } else {
        this.remoteService.interventionService.update(this.token, this.actualIntervention.id, {
          'failedPermissions': false
        }).pipe(take(1)).subscribe();
        this.loadingPermits = false;
        this.showPermitsRequest = false;
        this.permissionAccepted = true;
        this.readyServices();
      }
    });
  }

  getInformation() {
    this.remoteService.constantsService.getConstants(this.token).pipe(take(1)).subscribe((constants: any) => {
      this.actualIntervention = this.remoteService.interventionService.actualIntervention;
      this.loadDynamicStyles(this.actualIntervention.customStylesFolder);
      if (this.actualIntervention.company_config) {
        this.remoteService.interventionService.setCompanyConfig(this.actualIntervention.company_config);
        this.showLegalFooter = this.actualIntervention.company_config.showLegalFooter;
      }
      const conditionsAccepted = JSON.parse(localStorage.getItem('conditionsAccepted')) || [];
      if (conditionsAccepted.indexOf(this.actualIntervention.id) === -1) {
        this.router.navigate([`${this.token}`]);
      } else {
        if (this.actualIntervention.status === 3) {
          this.router.navigate([`final-page/${this.token}`]);
        }
      }
      this.loading = false;

    }, error => {
      console.log('error',error)
    });
  }

  readyServices(){
    this.remoteService.interventionService.removeIndexLoader();
    this.remoteService.iotService.connectIOT();
    this.remoteService.iotService.messages.subscribe(this.onMessage.bind(this));
    this.remoteService.interventionService.reloadStylesFromComponent();
    this.remoteService.interventionService.changeLabels();
    this.remoteService.languageService.browserLanguage();
    this.remoteService.callService.interventionReady(this.actualIntervention, this.device.userAgent);
  }

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  isIOS() {
    return this.device.platform === this.remoteService.interventionService.Platforms.IOS;
  }

  callAgent() {
    if (!this.ongoingCall) {
      this.ongoingCall = true;
      this.remoteService.callService.callAgent(this.actualIntervention, this.device.userAgent);
    }
  }

  answer() {
    if(!this.ongoingCall) {
      this.ongoingCall = true;
      this.remoteService.callService.answer();
    }
  }

  declineCall() {
    if (!this.ongoingCall) {
      this.remoteService.callService.decline();
    }
  }

  cancelCall() {
    if(this.ongoingCall) {
      this.ongoingCall = false;
      this.remoteService.callService.cancelCall()
    }
  }

  onMessage(message) {
    switch (message.event) {
      case IotMessageType.CREATE_SESSION:
        if (this.permissionAccepted) {
          this.remoteService.callService.incomingCall(this.actualIntervention, message);
        }
      break;

      case IotMessageType.CALL_DROPPED:
        this.ongoingCall = false;
        this.remoteService.callService.freeResources();
      break;

      case IotMessageType.DECLINED_CALL:
        this.ongoingCall = false;
        this.remoteService.callService.freeResources();
      break;

      case IotMessageType.CALL_HOLDED:
        this.remoteService.callService.holdCall();
      break;

      case IotMessageType.INTERVENTION_STATUS_CHANGE:
        if (message.status === 3) {
          this.router.navigate([`final-page/${this.token}`]);
        }
      break;
    }
  }

  landscapeOrientation() {
    console.log(window.matchMedia("(orientation: portrait)").matches);
    return window.matchMedia("(orientation: portrait)").matches
  }
}
