import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';

@Component({
  selector: 'app-final-page',
  templateUrl: './final-page.component.html',
  styleUrls: ['./final-page.component.scss']
})
export class FinalPageComponent implements OnInit {
  rate: number;
  reviewObject: Array<object> = [
    {check: false},
    {check: false},
    {check: false},
    {check: false},
    {check: false}
  ];
  rating: number;
  token: string;
  singleIntervention: any;
  device: any;
  rated = false;
  hideReview = sessionStorage.getItem('hideReview') == 'false' ? false : true;
  interventionReportEnable = sessionStorage.getItem('interventionReportEnable') == 'false' ? false : true;
  loading: boolean = true;
  dowloading: boolean = false;
  caseRefHtml: string = '';
  showLegalFooter: Boolean = false;

  constructor(
    private router: Router,
    public remoteService: RemoteLibraryService,
    public change: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.device = this.remoteService.interventionService.browserCheck();
    this.token = this.remoteService.interventionService.userToken
    this.getInformation();
    this.remoteService.interventionService.reloadStylesFromComponent();
    this.remoteService.interventionService.changeLabels();
    this.remoteService.languageService.browserLanguage();
  }

  loadDynamicStyles(customStylesFolder){
    if(customStylesFolder) {
      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
      }
    }
  }

  getInformation() {
      this.singleIntervention = this.remoteService.interventionService.actualIntervention;
      if (this.singleIntervention.company_config) {
        if (this.singleIntervention.company_config.labels) {
          const caseRefElement = this.singleIntervention.company_config.labels['SMARTVIDEO_WEBAPP_FINAL_PAGE_CASE_REF_ELEMENT'] || '';
          if (caseRefElement) {
            this.caseRefHtml = caseRefElement.replace('{caseRef}', this.singleIntervention.case_reference);
          }
        }
        this.showLegalFooter = this.singleIntervention.company_config.showLegalFooter;
      }
      this.loadDynamicStyles(this.singleIntervention.customStylesFolder);
      this.loading = false;
      this.remoteService.interventionService.removeIndexLoader();
      const conditionsAccepted = JSON.parse(localStorage.getItem('conditionsAccepted')) || [];
      if (conditionsAccepted.indexOf(this.singleIntervention.id) === -1) {
        this.router.navigate([`${this.token}`]);
      } else {
        if (this.singleIntervention.status === 2) {
          this.router.navigate([`call/${this.token}`]);
        } else if (this.singleIntervention.rate) {
          this.changeToRated();
        }
      }
  }

  sendFeedback() {
    this.remoteService.interventionService.rateIntervention(this.token, this.singleIntervention.id, this.rating).subscribe(res => {
      this.changeToRated();
    });
  }

  changeToRated() {
    this.rated = true;
    this.rating = this.rating !== undefined ? this.rating : this.singleIntervention.rate;
    this.change.detectChanges();
  }

  downloadReport() {
    this.dowloading = true;
    this.remoteService.interventionService.downloadReport(this.token, this.singleIntervention.id).subscribe((data: any) => {
      this.dowloading = false;
    });
  }
}
