import { Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CallPageComponent } from './call-page/call-page.component';
import { FinalPageComponent } from './final-page/final-page.component';
import { PermissionErrorComponent } from './permission-error/permission-error.component';
import { WarningBrowserComponent } from './warning-browser/warning-browser.component';
import { SupportBrowsersGuard } from '../guards/support-browsers.guard';
import {UseConditionsComponent} from './use-conditions/use-conditions.component';


export const routes : Routes = [
  {
    path: ':token',
    component: LandingPageComponent,
    canActivate: [
      SupportBrowsersGuard,
    ],
  },
  {
    path: 'call/:token',
    component: CallPageComponent,
    canActivate: [
      SupportBrowsersGuard,
    ],
  },
  {
    path: 'final-page/:token',
    component: FinalPageComponent,
    canActivate: [
      SupportBrowsersGuard,
    ],
  },
  {
    path: 'use-conditions/:token/:type',
    component: UseConditionsComponent,
  },
  {
    path: 'no-permission-error/:token',
    component: PermissionErrorComponent,
  },
  {
    path: 'warning-browser/:token',
    component: WarningBrowserComponent,
  },
];
