import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { RemoteLibraryService } from 'remote-library';

@Injectable({
  providedIn: 'root',
})
export class SupportBrowsersGuard implements CanActivate {

  token: string;

  isIos: boolean = navigator.userAgent.indexOf('iPhone') >= 0;
  isSafari: boolean = navigator.userAgent.indexOf('Safari') >= 0;
  notSupportBrowsers: boolean[] = [
    navigator.userAgent.indexOf('MiuiBrowser') >= 0,
    navigator.userAgent.indexOf('FB') >= 0,
  ];

  constructor(
    private remoteService: RemoteLibraryService, private router: Router,
  ) {
    this.token = this.remoteService.interventionService.userToken;
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    console.log('BrowserGuard triggered', navigator.userAgent);
    if (this.isIos) {
      if (navigator.userAgent.match('CriOS')) {
        return this.toBrowserError();
      }
    } else {
      for (const notSupport of this.notSupportBrowsers) {
        if (notSupport) {
          return this.toBrowserError();
        }
      }
    }
    return true;
  }

  toBrowserError(): boolean {
    console.log('INFO: Navega a:warning-browser con token:', this.token);
    this.router.navigate([`warning-browser/${this.token}`]);
    return false;
  }
}
