import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { AppComponent } from '../app.component';
import { InterventionModel } from "../dataModels/intervention-model";

@Component({
  selector: 'app-warning-browser',
  templateUrl: './warning-browser.component.html',
  styleUrls: ['./warning-browser.component.scss'],
})
export class WarningBrowserComponent implements OnInit, AfterViewInit {

  copiedUrl: string = '';
  openNotification: boolean;
  backgroundColor;
  logoNotSupported;
  isIOS: boolean;
  interventionObject: InterventionModel;
  hour;
  day;
  months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  }
  showLegalFooter: boolean = false;

  constructor(
    public remoteService: RemoteLibraryService,
    public appComponent: AppComponent
  ) {
    this.copiedUrl = window.location.href.replace('warning-browser/', '');
    this.isIOS = navigator.userAgent.indexOf('iPhone') >= 0;
    this.interventionObject = this.appComponent.inteventionObject;
  }

  ngOnInit() {
    console.log('WarningBrowserComponent', navigator.userAgent);
    this.remoteService.interventionService.changeLabels();
    this.remoteService.languageService.browserLanguage();
    this.openNotification = false;
    this.transformDate(this.interventionObject.scheduled_date);

    // Color de fondo, icono y texto por defecto
    this.backgroundColor = localStorage.getItem('backgroundColorBrowserNotSupportedSlave') || '#e3747e';
    this.logoNotSupported = localStorage.getItem('logoBrowserNotSupportedSlave') || '../../assets/images/x-mark.svg';

    if (this.remoteService.interventionService.actualIntervention.company_config) {
      this.showLegalFooter = this.remoteService.interventionService.actualIntervention.company_config.showLegalFooter;
    }
  }

  transformDate(scheduledDate) {
    const language =  localStorage.getItem('fixedLanguage') || window.navigator.language;
    let date = new Date (scheduledDate *1000);
    let month = this.remoteService.languageService.strLang(this.months[date.getMonth()]);
    this.day = `${month} ${date.getDate()}`;
    let minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}`: date.getMinutes();
    this.hour = `${date.getHours()}:${minutes}`;
    if (language.startsWith('es') || language.startsWith('pt')) {
      this.day = `${date.getDate()} de ${month}`;
    } else if (language.startsWith('tr')) {
      this.day = this.hour;
      this.hour = '';
    } else if (language.startsWith('sv') || language.startsWith('it')) {
      this.day = `${date.getDate()} ${month}`;
    }
    else if (language.startsWith('en')) {		
			switch (this.day) {
        case 1:
          this.day += 'st';
          break;
        case 2:
          this.day += 'nd';
          break;
        case 3:
          this.day += 'rd';
          break;     
        default:
          this.day += 'th';
          break;
      }
		}
  }

  ngAfterViewInit() {
    this.remoteService.interventionService.removeIndexLoader();
  }

  copyLink() {
    const aux = document.createElement('input');
    aux.setAttribute('value', this.copiedUrl);
    document.body.appendChild(aux);

    // IOS sólo permite document.execCommand('copy') para texto dentro de un contenedor de contenido editable.
    // Se resuelve alternando el contentEditable antes de ejecutar document.execCommand('copy')
    if (this.isIOS) {
      // Se guarda el contentEditable y readOnly
      const editable = aux.contentEditable;
      const readOnly = aux.readOnly;
      // Se crea un rango seleccionable
      const range = document.createRange();
      range.selectNodeContents(aux);
      // Se selecciona el rango
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      aux.setSelectionRange(0, 999999);
      // Se restaura los estados originales de contentEditable y readOnly
      aux.contentEditable = editable;
      aux.readOnly = readOnly;
    } else {
      // Unica accion a hacer en Android, que no se realiza para IOS
      aux.select();
    }

    // Se ejecuta el copiado
    const result = document.execCommand('copy');
    if (result) {
      this.showNotification();
    }
    document.body.removeChild(aux);
  }

  showNotification() {
    this.openNotification = true;
    setTimeout(() => {
      this.openNotification = false;
    }, 2000);
  }
}
