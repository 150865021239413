import { Component, OnInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Router } from '@angular/router';

@Component({
  selector: 'app-permission-error',
  templateUrl: './permission-error.component.html',
  styleUrls: ['./permission-error.component.scss']
})
export class PermissionErrorComponent implements OnInit {

  public permitionsErrImg;
  public isIOS;
  public companyPhone;
  private curIntervention;
  private token;
  public language: string;
  public device: string;
  public supportedLanguages: string[] = [
    'es',
    'en',
    'it',
    'pt',
    'tr',
    'sv',
    'fi',
    'de',
    'fr',
  ];
  showLegalFooter: boolean = false;

  constructor(
    public remoteService: RemoteLibraryService,
    private router: Router
    ) {
    this.token = this.remoteService.interventionService.userToken;
  }

  ngOnInit() {
    this.remoteService.languageService.browserLanguage();
    this.curIntervention = this.remoteService.interventionService.actualIntervention;
    this.cleanConditionsArray();
    this.isIOS = this.remoteService.callService.is_safari;
    this.companyPhone = this.curIntervention.companyPhone;
    this.loadDynamicStyles(this.curIntervention.customStylesFolder);
    this.checkSoAndLang();
    if (this.remoteService.interventionService.actualIntervention.company_config) {
      this.showLegalFooter = this.remoteService.interventionService.actualIntervention.company_config.showLegalFooter;
    }
  }

  cleanConditionsArray() {
    const conditionsAccepted = JSON.parse(localStorage.getItem('conditionsAccepted')) || [];
    let index = conditionsAccepted.indexOf(this.curIntervention.id);

    if (index !== -1) {
      conditionsAccepted[index] = null;
      localStorage.setItem("conditionsAccepted", JSON.stringify(conditionsAccepted));
    }
  }

  /**
   * @description comprueba idioma y sistema operativo del dispositivo, si el idioma no es soportado
   * se establece inglés por defecto.
   */
  checkSoAndLang() {
    this.device = this.isIOS ? 'iOs' : 'android';
    if (localStorage.getItem('fixedLanguage')) {
      this.language = (localStorage.getItem('fixedLanguage').substring(0, 2));
      return;
    }
    const navLang = navigator.language.substring(0, 2);
    this.language = this.supportedLanguages.includes(navLang) ? navLang : 'en';
  }

  /**
   * @description redirige a la landing.
   */
  goToLanding() {
    this.router.navigate([`${this.token}`]);
  }


  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      try {
        /* eslint-disable global-require */
        /* eslint-disable import/no-dynamic-require */
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
        /* eslint-enable import/no-dynamic-require */
        /* eslint-enable global-require */
        sessionStorage.setItem('customStylesFolder', customStylesFolder);
      } catch (error) {
        /* eslint-disable no-console */
        console.log(`No se han podido cargar los estilos personalizados de la compañía. ERROR: ${error}`);
        /* eslint-enable no-console */
      }
    }
  }

  restart() {
    this.router.navigate([this.token]).then(res => {
      window.location.reload();
    })
  }

}
